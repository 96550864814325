<template>
  <Modal
    :activeModal="activeModal"
    :widthModal="450"
    @closeModal="closeModal">
   <template v-slot:btnActivator>
      <v-btn
        tile
        v-if="permiteAcao($route, 'add')" 
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        >Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ isEdit ? 'Editar Tipo de Índice' : 'Cadastrar Tipo de Índice' }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <!-- <v-card-title class="modal-cadastro-titulo">
          
        </v-card-title> -->

          <v-card-text class="modal-cadastro-form">
          <v-row>

            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-text-field  :error-messages="errorName" v-model="item.name" label="Nome" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" />
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-text-field :error-messages="errorDescription" v-model="item.description" label="Descrição" @input="$v.item.description.$touch()" @blur="$v.item.description.$touch()" />
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-select :error-messages="errorindexTypeRecurrence" :items="listItensRecorrencia" label="Recorrência" v-model="item.indexTypeRecurrence" @input="$v.item.indexTypeRecurrence.$touch()" @blur="$v.item.indexTypeRecurrence.$touch()"></v-select>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
            </v-col>
           <!--  <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-select
                v-model="item.chipContractId"
                :items="listContracts"
                label="Contrato"></v-select>
            </v-col> -->
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'

import validacao from './validacao'
import moment from 'moment'

export default {
  name: 'BrModalCadastroMetas',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    activeModal: false,
    loading: false,
    listItensRecorrencia: [
      { text: 'Diário', value: 'D' },
      { text: 'Mensal', value: 'M' },
      { text: 'Anual', value: 'A' }
    ]
  }),
  computed: {
    ...mapGetters('tiposIndices', ['item']),
    ...mapGetters('roles', ['permiteAcao']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    // this.obterItensSelect()
    Events.$on('cadastro::openModalCadastro', () => {
      this.openModal()
    })
  },
  methods: {
    ...mapActions('tiposIndices', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true
      const dados = this.item
      dados.refDate = moment().format('YYYY-MM-DD')

      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg || 'Edição não realizado!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error || 'Cadastro não realizado!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    }
  },

  validations () {
    return  {
      item: {
        name: { required },
        description: { required, minLength: minLength(5) },
        indexTypeRecurrence: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import './../../../../assets/styles/components/formularios';
  @import './../../../../assets/styles/components/modal-cadastro';
</style>