export default {
  // validações dados ICCID
  errorName () {
    const errors = []
    if (!this.$v.item.name.$dirty) return errors
    !this.$v.item.name.required && errors.push('Campo é obrigatório.')
    return errors
  },

  errorDescription () {
    const errors = []
    if (!this.$v.item.description.$dirty) return errors
    !this.$v.item.description.required && errors.push('Campo é obrigatório.')
    !this.$v.item.description.minLength && errors.push('Tamanho maior que 5.')
    return errors
  },
  errorindexTypeRecurrence () {
    const errors = []
    if (!this.$v.item.indexTypeRecurrence.$dirty) return errors
    !this.$v.item.indexTypeRecurrence.required && errors.push('Campo é obrigatório.')
    return errors
  }
}